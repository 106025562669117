var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-5",attrs:{"elevation":"1","tile":""}},[_c('v-row',[_c('v-col',{staticClass:"subtitle-1 font-weight-bold mb-5",attrs:{"cols":"12","md":"6"}},[_vm._v(" INFORME RECLAMOS TRAMITADOS POR DEFENSOR ")]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"6"}},[_c('v-radio-group',{staticClass:"mt-0 mb-0",attrs:{"row":"","label":"Agrupación por fecha de:"},model:{value:(_vm.groupBySearch),callback:function ($$v) {_vm.groupBySearch=$$v},expression:"groupBySearch"}},[_c('v-radio',{attrs:{"label":"creación","value":false}}),_c('v-radio',{attrs:{"label":"ultima modificación","value":true}})],1)],1)],1),_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.search($event)}},model:{value:(_vm.form.isValid),callback:function ($$v) {_vm.$set(_vm.form, "isValid", $$v)},expression:"form.isValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"label":"Fecha desde","outlined":"","dense":"","items":_vm.dateSelect},model:{value:(_vm.dateSince),callback:function ($$v) {_vm.dateSince=$$v},expression:"dateSince"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"label":"Fecha hasta","outlined":"","dense":"","items":_vm.dateSelect},model:{value:(_vm.dateUntil),callback:function ($$v) {_vm.dateUntil=$$v},expression:"dateUntil"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"label":"Banco","outlined":"","dense":"","items":_vm.banks.map(function (bank) {
                return { value: bank.code, text: bank.name };
              })},model:{value:(_vm.bank),callback:function ($$v) {_vm.bank=$$v},expression:"bank"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","small":"","dark":"","color":"blue lighten-3","loading":_vm.processing},on:{"click":_vm.clean}},[_c('v-icon',[_vm._v(" mdi-eraser ")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"type":"submit","fab":"","small":"","color":"primary","loading":_vm.processing,"disabled":!_vm.form.isValid || _vm.processing}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-magnify ")])],1),_c('ImportExcel',{attrs:{"disabled":_vm.dataComputed.length == 0,"loading":_vm.processing,"data":_vm.dataComputedExcel,"json_fields":_vm.headersComputedExcel,"name":"Informe-Reclamos-Tramitados-Defensor-"}})],1)],1)],1),(_vm.dataComputed.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"9","xl":"7"}},[_c('v-data-table',{attrs:{"headers":_vm.headersComputed,"items":_vm.dataComputed,"items-per-page":1000,"hide-default-footer":"","dense":"","show-expand":"","item-key":"userIdDDC","single-expand":true,"expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.userIdDDC",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNameDefender(item.userIdDDC, "Sin defensores asignados"))+" ")]}},{key:"item.v2025",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.v2025))+" ")]}},{key:"item.t2025",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.t2025))+" ")]}},{key:"item.v2024",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.v2024))+" ")]}},{key:"item.t2024",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.t2024))+" ")]}},{key:"item.v2023",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.v2023))+" ")]}},{key:"item.t2023",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.t2023))+" ")]}},{key:"item.v2022",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.v2022))+" ")]}},{key:"item.t2022",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.t2022))+" ")]}},{key:"item.v2021",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.v2021))+" ")]}},{key:"item.t2021",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.t2021))+" ")]}},{key:"item.v2020",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.v2020))+" ")]}},{key:"item.t2020",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.t2020))+" ")]}},{key:"item.v2019",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.v2019))+" ")]}},{key:"item.t2019",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.t2019))+" ")]}},{key:"expanded-item",fn:function(ref){
              var headers = ref.headers;
              var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"ma-2",attrs:{"elevation":"3","color":"purple lighten-5"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.getNameDefender( item.userIdDDC, "Sin defensores asignados" ))+" "),_c('v-spacer'),_c('ImportExcel',{attrs:{"disabled":_vm.dataBankDefenderExcel(item).length == 0,"loading":_vm.processing,"data":_vm.dataBankDefenderExcel(item),"json_fields":_vm.headersBankExcel,"name":("Informe-Reclamos-Tramitados-Defensor-" + (_vm.getNameDefender(
                        item.userIdDDC,
                        'Sin defensores asignados'
                      )) + "-")}})],1),_c('v-data-table',{staticClass:"purple lighten-5",attrs:{"headers":_vm.headersBank,"items":_vm.dataBankDefender(item),"items-per-page":1000,"item-key":"bankCode","show-expand":"","single-expand":true,"expanded":_vm.expandedBank,"hide-default-footer":"","dense":""},on:{"update:expanded":function($event){_vm.expandedBank=$event}},scopedSlots:_vm._u([{key:"item.bankCode",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getBankName(item.bankCode))+" ")]}},{key:"item.v2025",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.v2025))+" ")]}},{key:"item.t2025",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.t2025))+" ")]}},{key:"item.v2024",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.v2024))+" ")]}},{key:"item.t2024",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.t2024))+" ")]}},{key:"item.v2023",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.v2023))+" ")]}},{key:"item.t2023",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.t2023))+" ")]}},{key:"item.v2022",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.v2022))+" ")]}},{key:"item.t2022",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.t2022))+" ")]}},{key:"item.v2021",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.v2021))+" ")]}},{key:"item.t2021",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.t2021))+" ")]}},{key:"item.v2020",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.v2020))+" ")]}},{key:"item.t2020",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.t2020))+" ")]}},{key:"item.v2019",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.v2019))+" ")]}},{key:"item.t2019",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.t2019))+" ")]}},{key:"expanded-item",fn:function(ref){
                      var headers = ref.headers;
                      var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"ma-2",attrs:{"elevation":"3","color":"indigo lighten-5"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.getBankName(item.bankCode))+" "),_c('v-spacer'),_c('ImportExcel',{attrs:{"disabled":_vm.dataDetailsBankDefenderExcel(item).length == 0,"loading":_vm.processing,"data":_vm.dataDetailsBankDefenderExcel(item),"json_fields":_vm.headersBankDetailsExcel,"name":("Informe-Reclamos-Tramitados-Defensor-" + (_vm.getNameDefender(
                                item.userIdDDC,
                                'Sin defensores asignados'
                              )) + "-" + (_vm.getBankName(item.bankCode)) + "-")}})],1),_c('v-data-table',{staticClass:"indigo lighten-5",attrs:{"headers":_vm.headersBankDetails,"items":_vm.dataDetailsBankDefender(item),"items-per-page":1000,"item-key":"month","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.month",fn:function(ref){
                              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getMonth(item.month))+" ")]}},{key:"item.v2025",fn:function(ref){
                              var item = ref.item;
return [_vm._v(_vm._s(_vm.formatNumber(item.v2025)))]}},{key:"item.t2025",fn:function(ref){
                              var item = ref.item;
return [_vm._v(_vm._s(_vm.formatNumber(item.t2025)))]}},{key:"item.v2024",fn:function(ref){
                              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.v2024))+" ")]}},{key:"item.t2024",fn:function(ref){
                              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.t2024))+" ")]}},{key:"item.v2023",fn:function(ref){
                              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.v2023))+" ")]}},{key:"item.t2023",fn:function(ref){
                              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.t2023))+" ")]}},{key:"item.v2022",fn:function(ref){
                              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.v2022))+" ")]}},{key:"item.t2022",fn:function(ref){
                              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.t2022))+" ")]}},{key:"item.v2021",fn:function(ref){
                              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.v2021))+" ")]}},{key:"item.t2021",fn:function(ref){
                              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.t2021))+" ")]}},{key:"item.v2020",fn:function(ref){
                              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.v2020))+" ")]}},{key:"item.t2020",fn:function(ref){
                              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.t2020))+" ")]}},{key:"item.v2019",fn:function(ref){
                              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.v2019))+" ")]}},{key:"item.t2019",fn:function(ref){
                              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.t2019))+" ")]}}],null,true)})],1)],1)]}}],null,true)})],1)],1)]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',_vm._l((_vm.headersComputed),function(header){return _c('td',{key:header.value},[_vm._v(" "+_vm._s(_vm.sumarizeNew(header))+" ")])}),0)])],2)],1)],1):_vm._e(),(_vm.dataComputed.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('canvas',{attrs:{"height":"100","id":"claims-graph"}})])],1):_vm._e()],1),_c('Processing',{attrs:{"viewProcessing":_vm.processing,"title":"Cargando..."}}),_c('Alert',{attrs:{"dialog":_vm.alert.show,"msj":_vm.alert.message},on:{"close":function($event){_vm.alert.show = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }